import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import ViewUI from "view-design"; //iview UI
import "view-design/dist/styles/iview.css";
import Viewer from "v-viewer"; //图片查看器
import "viewerjs/dist/viewer.css";
import Swiper from "vue-awesome-swiper"; //走马灯
import "swiper/dist/css/swiper.css";
import echarts from "echarts";
import moment from "moment";
import vcolorpicker from 'vcolorpicker'
import TIM from 'tim-js-sdk'

import {
    Notice
} from "view-design";

import TRTCCalling from 'trtc-calling-js'; //语音通话
// import "../public/lib/flexible.js"; //阿里可伸缩布局
import "../public/lib/coordinateTransform.js"; //各个坐标换算方法
import "html2canvas"; //截图插件


import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

// import '@babel/polyfill'//兼容ie11(还是无法显示,待解决)
// import Es6Promise from 'es6-promise'
// Es6Promise.polyfill();
import VueZoomer from 'vue-zoomer'
Vue.use(VueZoomer)
// 引入自定义
 import {sy_get, sy_delete,  sy_post} from "./utils/http.js";


import {
    Java_fromdata,
    Java_post,
    fetchGet,
    Delete,
    file_post
} from "./utils/JavaHttp.js";
import axios from 'axios';


import PublicData from "./utils/PublicData.js";
import tempFn from "./components/tempCommon/tempCommon.js";
//Terra请求
import {
    T_post,
    T_get,
    T_Delete
} from "./utils/TerraHttp.js";

Vue.config.productionTip = false;

Vue.use(ViewUI);
Vue.use(Swiper);
Vue.use(Viewer, {
});
Vue.use(vcolorpicker)
Vue.use(tempFn); //全局组件

//定义全局变量
// Vue.prototype.$post = post;
// Vue.prototype.$fromdata = fromdata;
Vue.prototype.$get = fetchGet;
Vue.prototype.$delete = Delete;
Vue.prototype.$post = Java_post;
Vue.prototype.$filePost = file_post;

Vue.prototype.$fromdata = Java_fromdata;
Vue.prototype.$_bus = new Vue(); //注册,触发,销毁,全局事件
Vue.prototype.$publicdata = PublicData; //调用公共数据和事件
Vue.prototype.$echarts = echarts;
Vue.prototype.$moment = moment;
Vue.prototype.$notice = Notice;
Vue.prototype.$T_post = T_post;
Vue.prototype.$T_get = T_get;
Vue.prototype.$T_Delete = T_Delete;
Vue.prototype.$sy_post = sy_post;
Vue.prototype.$sy_delete = sy_delete;
Vue.prototype.$sy_get = sy_get;

// 语音通话>>>>>
Vue.prototype.$trtcCalling = new TRTCCalling({
    SDKAppID: 1400568409
});
// Vue.prototype.$tim = TIM.create({
//     SDKAppID: 1400568409
// });
Vue.prototype.TrtcCalling = TRTCCalling;
// <<<<<语音通话
// Vue.prototype.$http = axios  // axios请求
// Vue.prototype.$get = fetchGet  // axios请求
Vue.prototype.$online = true; // 内外网版本切换

Vue.prototype.$public = true; // 公共版本切换
Vue.prototype.$CSTJ = false; // 长沙特警定制化 live/live/index.vue的mounted事件中需要修改判断,使initData()的参为用户团队id
Vue.prototype.$CSXF = false; // 长沙消防定制化_index.scss中还有一处修改
Vue.prototype.$SYC = false; //湖南烟草
Vue.prototype.$JL = false; //吉林省  //基于公网版开发的
Vue.prototype.$mainHost = 'https://www.jointflight.cn/jflight';
//Vue.prototype.$mainHost = 'http://129.204.9.15:9034';// 消防
// Vue.prototype.$mainHost = 'http://36.139.4.41:9044';// 南宁
// Vue.prototype.$mainHost = 'http://43.4.201.100:9044'; // 特警
// Vue.prototype.$mainHost = 'http://192.168.100.65:9044'; // 萧山特警
// Vue.prototype.$mainHost = 'http://10.72.0.195:9044'; // 烟草
//Vue.prototype.$mainHost = 'http://129.204.9.15:9054'; // 吉林省


//
Vue.prototype.$theme = {
    'dark': true
}; // 主题色,默认暗

// import zxh_commonFun from "./utils/zxh_commonFun";
// Vue.prototype.$zxh_fun = zxh_commonFun;
// 根据ip设置地图中心
Vue.prototype.$Center = [112.97935279, 28.21347823]; // 长沙
// Vue.prototype.$Center = [106.788562, 23.794266];// 南宁
if (Vue.prototype.$online) {
    console.log("根据ip设置地图中心");
    let position = [112.97935279, 28.21347823]; //106.788562,23.794266
    // 根据IP地址获取坐标
    axios
        .create({
            baseURL: 'https://restapi.amap.com',
            // baseURL: ''
        })
        .get(
            '/v3/ip', {
                params: {
                    key: '4fd128de3f3dab6b7fdfab09ea6da317',
                    output: 'json',
                },
            }, {
                timeout: 1000,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
            }
        )
        .then(function (res) {
            console.log('高德根据IP地址获取坐标', res);
            if (res.status == 200) {
                var locations = res.data.rectangle.split(';');
                var southwest = locations[0].split(',');
                var northeast = locations[1].split(',');
                console.log(southwest, northeast);
                var IPlocation = [
                    (Number(southwest[0]) + Number(northeast[0])) / 2,
                    (Number(southwest[1]) + Number(northeast[1])) / 2,
                ];
                console.log(IPlocation);
                Vue.prototype.$Center = IPlocation;
            } else {
                console.log(res.statusText);
                Vue.prototype.$Center = position;
            }
        })
        .catch(function (error) {
            console.log(error);
            Vue.prototype.$Center = position;
        });
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
