import axios from "axios";
import router from "../router";
import apiURL from "./apiURL";

import {
    Notice
} from "view-design";

const instance = axios.create({
    baseURL: apiURL.GW_baseURL
});

//http 请求拦截器
instance.interceptors.request.use(
    (config) => {
        config.headers.token = sessionStorage.getItem("token");
        return config;
    },
    (error) => {
        // LoadingBar.error();
        Notice.error({
            title: "后台接口请求错误",
            desc: error,
        });
        return Promise.reject(error);
    }
);




//http 响应拦截器
instance.interceptors.response.use(
    (response) => {
        // LoadingBar.finish();
        return response;
    },
    (error) => {
        console.log("error", error.response);
        if (error.response.status === 504) {
            Notice.error({
                title: "网络连接超时",
            });
        } else if (error.response.status === 502) {
            Notice.error({
                title: "服务器返回异常",
            });
        } else if (error.response.data.code == 3) {
            Notice.error({
                title: "您的账号在别处登录",
            });
            router.push("/");
        } else {
            Notice.error({
                title: "后台接口响应错误",
                desc: error,
            });
        }
        // LoadingBar.error();

        return Promise.reject(error);
    }
);

export function Java_post (url, data = {}) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem("token") ?
            sessionStorage.getItem("token") :
            "";
        console.log(sessionStorage.getItem("Eid"));
        let eid = sessionStorage.getItem("Eid") ?
            sessionStorage.getItem("Eid") :
            null;
     //   console.log('====',eid);
        data.eid = eid? Number(eid) : null;
        // data.eid = '';
        data.isEnable = Number(sessionStorage.getItem("isEnable"));
        instance.post(url, data, {
            headers: {
                "token": token,
            },
        }).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "登录状态过期",
                        desc: response.data.msg_customer,
                    });
                    router.push("/");
                } else if (response.data.code == 3) {
                    Notice.error({
                        title: "登录状态过期",
                        desc: response.data.msg_customer,
                    });
                    router.push("/");
                }else if (response.data.code == 0) {
                    resolve(response.data);
                } else if (response.data.code == 1) {
                    resolve(response.data);
                } else {
                    Notice.error({
                        title: response.data.msg_customer,
                    });
                }
            },
            (err) => {
                reject(err);
            }
        ).catch((err) => {
            Notice.error({
                title: "登录状态过期",
                desc: err.data,
            });
        });
    });
}

export function file_post (url, data = {}) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem("token") ?
            sessionStorage.getItem("token") :
            "";
        instance.post(url, data, {
            headers: {
                "token": token,
            },
        }).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "登录状态过期",
                        desc: response.data.msg_customer,
                    });
                    router.push("/");
                } else if (response.data.code == 3) {
                    Notice.error({
                        title: "登录状态过期",
                        desc: response.data.msg_customer,
                    });
                    router.push("/");
                }else if (response.data.code == 0) {
                    resolve(response.data);
                } else if (response.data.code == 1) {
                    resolve(response.data);
                } else {
                    Notice.error({
                        title: response.data.msg_customer,
                    });
                }
            },
            (err) => {
                reject(err);
            }
        ).catch((err) => {
            Notice.error({
                title: "登录状态过期",
                desc: err.data,
            });
        });
    });
}

export function Java_fromdata (url, data) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem("token") ?
            sessionStorage.getItem("token") :
            "";
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
                "token": token,
            },
        };
        instance.post(url, data, config).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}

export function Delete (url){
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                'x-auth-token': sessionStorage.getItem("token")
                    ? sessionStorage.getItem("token")
                    : "", //测试服
            },
        };
        instance.delete(url, config).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
// axios get请求
export function fetchGet (url, param) {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: param
        })
            .then(response => {
                resolve(response.data)
            }, err => {
                reject(err)
            })
            .catch((error) => {
                reject(error)
            })
    })
}
