// 定义公共数据和事件
import CryptoJS from 'crypto-js'
import axios from 'axios'
import {
    Notice,
    LoadingBar
} from 'view-design'


let publicData = {
    // 数据
    data: {

    },


    // 方法============================================================
    //密码加密
    encrypt(str) {
        let key = CryptoJS.enc.Utf8.parse('HUNANDAGOUKEJIGS');
        let strs = CryptoJS.enc.Utf8.parse(str);
        const encryptedStr = CryptoJS.AES.encrypt(strs, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7 //Pkcs7 ZeroPadding AnsiX923
        });
        // var result = btoa(encodeURI(encryptedStr));
        return encryptedStr.toString();
    },
    // 验证用户是否登录并返回用户信息
    CheckuserLogin() {
        var user_info = sessionStorage.getItem('user_info') ? sessionStorage.getItem('user_info') : '';
        if (user_info) {
            return JSON.parse(user_info)
        } else {
            Notice.error({
                title: '错误',
                desc: "用户未登录"
            });
            setTimeout(() => {
                window.location.href = '/'
            }, 1000)
        }

    }

};
export default publicData