import axios from "axios";
import router from "../router";
import { Notice, LoadingBar } from "view-design";
import apiURL from "./apiURL";

// axios.defaults.timeout = 6000;
// axios.defaults.baseURL = "/api";
//axios.defaults.baseURL = "http://129.204.9.15:9034/jflight";

// 上云  token 不一样

axios.defaults.baseURL = apiURL.SY_baseURL




//http 请求拦截器
axios.interceptors.request.use(
    (response) => {
        // LoadingBar.start();
        return response;
    },
    (error) => {
        // LoadingBar.error();
        Notice.error({
            title: "请求错误",
            desc: error.response.status,
        });
        return Promise.reject(error);
    }
);
//http 响应拦截器
axios.interceptors.response.use(
    (response) => {
        // LoadingBar.finish();
        return response;
    },
    (error) => {
        // LoadingBar.error();
        Notice.error({
            title: "响应错误",
            desc: error.response.status,
        });
        return Promise.reject(error);
    }
);


export function sy_post (url, data = {}) {
    return new Promise((resolve, reject) => {
        let token = sessionStorage.getItem("sy_token")
            ? sessionStorage.getItem("sy_token")
            : "";
        let config = {
            headers: {
                'x-auth-token': token
            },
        };
        axios.post(url, data, config).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
export function sy_get (url) {
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                'x-auth-token': sessionStorage.getItem("sy_token")
                    ? sessionStorage.getItem("sy_token")
                    : "", //测试服
            },
        };
        axios.get(url, config).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
export function sy_delete (url){
    return new Promise((resolve, reject) => {
        let config = {
            headers: {
                'x-auth-token': sessionStorage.getItem("sy_token")
                    ? sessionStorage.getItem("sy_token")
                    : "", //测试服
            },
        };
        axios.delete(url, config).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
/*export function post (url, data = {}) {
    return new Promise((resolve, reject) => {
        var token = sessionStorage.getItem("token")
            ? sessionStorage.getItem("token")
            : "";
        axios.post(url + token, data).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}*/

/*export function fromdata (url, data) {
    return new Promise((resolve, reject) => {
        var token = sessionStorage.getItem("token")
            ? sessionStorage.getItem("token")
            : "";
        let config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };
        axios.post(url + token, data, config).then(
            (response) => {
                if (response.data.code == 2) {
                    Notice.error({
                        title: "系统提醒",
                        desc: response.data.msg,
                    });
                    router.push("/");
                } else {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}*/



