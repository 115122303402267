// 导出 对应的不同接口的URL地址
const configUrl = {

  // baseURL: "/Java",

   // GW_baseURL: 'http://129.204.9.15:7064/jflight', //福林
    GW_baseURL: 'https://fuan.jointflight.cn/jflight', //福林
  //  GW_baseURL: "/Java",



  //    GW_baseURL: 'http://192.168.0.172:8097/jflight', //正式


  //上云
  SY_baseURL: 'https://pilot.jointflight.cn', //上云
 //  SY_baseURL: 'http://192.168.0.137:9883', //上云



}

export default configUrl
