import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "/login",
},
{
    path: "/login",
    name: "登录",
       component: () => import("@/components/login.vue"),
    // component: () => import("@/components/cstj.vue"),
    //  component: () => import("@/components/csxf.vue"),
},

{
    path: '/incident',
    name: '事件',
    component: () => import("@/components/incident.vue"),
},
{
    path: '/JilinProvince',
    name: '吉林省',
    component: () => import("@/components/JilinProvince/JilinProvince.vue"),
},
{
    path: "/index",
    name: "导航",
    component: () => import("@/components/lay_head.vue"),
    redirect: "/live/watch-lists",
    children: [
        {
            path: "project",
            name: "项目",
            component: () => import("@/components/project/ProjectBar_Level_2.vue"),
            // redirect: "/index/project/ProjectBar_Level_2",
            children: [
                // {
                //     path: "ProjectBar_Level_1",
                //     name: "一级工程列表详情",
                //     component: () =>
                //         import("@/components/project/ProjectBar_Level_1.vue"),
                // },
                {
                    path: "ProjectBar_Level_2",
                    name: "二级工程列表详情",
                    component: () =>
                        import("@/components/project/ProjectBar_Level_2.vue"),
                },
                // {
                //     path: "ProjectBar_task",
                //     name: "任务列表详情",
                //     component: () => import("@/components/project/ProjectBar_task.vue"),
                // },
                // {
                //     path: "TaskBar",
                //     name: "任务列表详情",
                //     component: () => import("@/components/project/index.vue"),
                //     redirect: "/index/project/TaskBar",

                // },
                // {
                //     path: "TaskBar",
                //     name: "任务",
                //     component: () => import("@/components/project/TaskBar.vue"),
                // },
            ],
        },
        {
            path: "PT",
            name: "任务列表详情",
            component: () => import("@/components/project/index.vue"),
            redirect: "/index/PT/TaskBar",
            children: [{
                path: "TaskBar",
                name: "任务",
                component: () => import("@/components/project/TaskBar.vue"),
            },]

        },
        // {
        //     path: "CreateProject",
        //     name: "新建项目",
        //     component: () => import("@/components/project/CreateProject.vue"),
        // },
        {
            path: "/statistics/index",
            name: "飞行统计",
            component: () => import("@/components/statistics/index.vue"),
        },
        {
            path: "/statistics/trajectory-playback",
            name: "轨迹回放",
            component: () =>
                import("@/components/statistics/trajectoryPlayback/index.vue"),
        },
        {
            path: "/statistics/LawPlay",
            name: "执法记录仪回放",
            component: () =>
                import("@/components/statistics/LawPlay/index.vue"),
        },
        {
            path: "/statistics/records",
            name: "飞行记录",
            component: () => import("@/components/statistics/records/index.vue"),
        },
        {
            path: "/live/watch-lists",
            name: "监控列表",
            component: () => import("@/components/live/watch/index.vue"),
        },
        {
            path: "/live/live-lists",
            name: "实时直播",
            component: () => import("@/components/live/live/index.vue"),
        },
        {
            path: "/rapidBiuldFigure",
            name: "发布快速拼图任务",
            component: () => import("@/components/live/watch/rapidBiuldFigure.vue"),
        },
        {
            path: "/route-specification",
            name: "航线规划",
            component: () => import("@/components/airLine/index.vue"),
        },
        {
            path: "/create/air-line/application",
            name: "创建航线任务",
            component: () => import("@/components/airLine/application.vue"),
        },
        {
            path: "/create/air-line/3D-line",
            name: "创建三维航线任务",
            component: () => import("@/components/airLine/3Dline.vue"),
        },
        {
            path: "equipment",
            name: "设备",
            component: () => import("@/components/equipment/index.vue"),
            redirect: "/index/equipment/UavManage",
            // redirect: "/index/equipment/UavManageCopy",
            children: [
                {
                    path: "UavManage",
                    name: "无人机管理",
                    component: () => import("@/components/equipment/UavManage.vue"),
                },
                // {
                //     path: "BatteryManage",
                //     name: "电池管理",
                //     component: () => import("@/components/equipment/BatteryManage.vue"),
                // },
                // {
                //     path: "UsageLog",
                //     name: "使用记录",
                //     component: () => import("@/components/equipment/UsageLog.vue"),
                // },
                // {
                //     path: "EquipmentMaintenance",
                //     name: "设备保养",
                //     component: () =>
                //         import("@/components/equipment/EquipmentMaintenance.vue"),
                // },
                {
                    path: "table",
                    name: "table",
                    component: () => import("@/components/equipment/table.vue"),
                    redirect: "/index/equipment/table/tableOne",
                    children: [{
                        path: "tableOne",
                        name: "tableOne",
                        component: () => import("@/components/equipment/tableOne.vue"),
                    },
                    {
                        path: "tableTwo",
                        name: "tableTwo",
                        component: () => import("@/components/equipment/tableTwo.vue"),
                    },
                    {
                        path: "tableThree",
                        name: "tableThree",
                        component: () =>
                            import("@/components/equipment/tableThree.vue"),
                    },
                    {
                        path: "tableFour",
                        name: "tableFour",
                        component: () => import("@/components/equipment/tableFour.vue"),
                    },
                    {
                        path: "tableFive",
                        name: "tableFive",
                        component: () => import("@/components/equipment/tableFive.vue"),
                    },
                    {
                        path: "tableSix",
                        name: "tableSix",
                        component: () => import("@/components/equipment/tableSix.vue"),
                    },
                    ],
                },
            ],
        },
        {
            path: "team",
            name: "团队",
            component: () => import("@/components/team/index.vue"),
        },
        {
            path: "resource",
            name: "资源",
            component: () => import("@/components/resource/index.vue"),
            redirect: "/index/resource/modal",
            children: [
                {
                    path: "/overview",
                    name: "总览",
                    component: () => import("@/components/Overview/index.vue"),
                },
                {
                    path: "modal",
                    name: "模型正射",
                    component: () => import("@/components/resource/modal.vue")
                },
                {
                    path: "ermodal",
                    name: "二维地图",
                    component: () => import("@/components/resource/ermodal.vue")
                },
                {
                    path: "panorama",
                    name: "全景列表",
                    component: () => import("@/components/Panorama/mgrPanorma.vue"),
                },
                {
                    path: "media",
                    name: "媒体资源",
                    component: () => import("@/components/resource/media.vue"),
                },
                {
                    path: "rapidMap",
                    name: "快速拼图",
                    component: () => import("@/components/resource/rapidMap.vue")
                },
                {
                    path: "markList",
                    name: "标注成果",
                    component: () => import("@/components/resource/markList.vue")
                },
            ]
        },
        {
            path: "/shangyun/index",
            name: "上云",
            component: () => import("@/components/shangyun/index.vue"),
            redirect: "/shangyun/live/watch-lists",
            children: [
                {
                    path: "/shangyun/lane/index",
                    name: "航线",
                    component: () => import("@/components/shangyun/lane/index.vue"),
                },
                {
                    path: "/shangyun/live/watch-lists",
                    name: "监控列表",
                    component: () => import("@/components/shangyun/watch/index.vue"),
                },
                {
                    path: "/shangyun/live/live-lists",
                    name: "实时直播",
                    component: () => import("@/components/shangyun/live/index.vue"),
                },
                {
                    path: "/shangyun/resource/media",
                    name: "媒体资源",
                    component: () => import("@/components/shangyun/resource/media.vue"),
                },

            ]
        },

        {
            path: "panorama",
            name: "vrList",
            component: () => import("@/components/Panorama/mgrPanorma.vue"),
        },



        {
            // path: "/rapidMap/label",
            // name: "快速对比",
            // component: () => import("@/components/resource/contrast.vue"),
            //==========================================================================
            path: "/rapidMap/label",
            name: "快拼标注",
            component: () => import("@/components/resource/label.vue"),
        },
        {
            path: "/rapidMap/screenshot",
            name: "保存截图",
            component: () => import("@/components/resource/screenshot.vue"),
        },

        {
            path: "/resourceIndex",
            name: "新资源",
            component: () => import("@/components/resource/Terra/index.vue"),
            children: [{
                path: "Terra",
                name: "新资源列表",
                component: () =>
                    import("@/components/resource/Terra/resourceList.vue"),
            },
            {
                path: "establish",
                name: "云计算选择绑定建图任务",
                component: () =>
                    import("@/components/resource/Terra/establish.vue"),
            },
            {
                path: "uploadingPage",
                name: "云计算上传页",
                component: () =>
                    import("@/components/resource/Terra/uploadingPage.vue"),
            },
                // {
                //     path: "modelShou",
                //     name: "模型查看",
                //     component: () =>
                //         import("@/components/resource/Terra/modelShou.vue"),
                // },
                // {
                //     path: "orthographicShou",
                //     name: "正射查看",
                //     component: () =>
                //         import("@/components/resource/Terra/orthographicShou.vue"),
                // },
            ],
        },
        {
            path: "/modelShou",
            name: "模型查看",
            component: () => import("@/components/resource/Terra/modelShou.vue"),
        },
        {
            path: "/orthographicShou",
            name: "正射查看",
            component: () =>
                import("@/components/resource/Terra/orthographicShou.vue"),
        },
        // {
        //   path: "/rapidMap/screenshot",
        //   name: "登记",
        //   component: () => import("@/components/resource/screenshot.vue"),

        // }
        // {
        //     path: "noFlyZone",
        //     name: "虚拟护栏",
        //     component: () => import("@/components/NFzone/NFzone.vue"),
        // },
        {
            path: "apk",
            name: "APP管理",
            component: () => import("@/components/apk.vue"),
        },
        // 任务
        {
            path: '/index/task',
            name: 'task',
            component: () => import("@/components/task/index.vue"),
            redirect: "/index/task/task",
            children: [{
                path: "task",
                name: "任务管理",
                component: () => import("@/components/task/task.vue"),
            },
            {
                path: "examine",
                name: "审核管理",
                component: () => import("@/components/task/examine.vue"),
            },
            {
                path: "noFlyZone",
                name: "虚拟护栏管理",
                component: () => import("@/components/NFzone/NFzone.vue"),
            },
            ]
        },

        // // 审核
        // {
        //     path: '/index/examine',
        //     name: 'examine',
        //     component: () => import("@/components/task/examine")
        // },
        // 全景图编辑
        {
            path: '/index/edtiPanorama',
            name: 'EdtiPanorama',
            component: () => import("@/components/Panorama/editPanorama.vue")
        },
        // // 全景图详情
        // {
        //     path: '/index/detailPanorama',
        //     name: 'DetailPanorama',
        //     component: () => import("@/components/Panorama/detailPanorama.vue")
        // },
        // 全景图管理
        {
            path: '/index/mgrPanorama',
            name: 'vrMgr',
            component: () => import("@/components/Panorama/mgrPanorma.vue")
        },
        {
            path: '/model/List',
            name: 'ModelList',
            component: () => import("@/components/model/index-Mars.vue")
        },
        {
            path: '/trackVideo/index',
            name: 'trackVideo',
            component: () => import("@/components/trackVideo/index.vue")
        },

    ],
},
    // 全景图编辑
    // {
    //     path: '/index/edtiPanorama',
    //     name: 'EdtiPanorama',
    //     component: () => import("@/components/Panorama/editPanorama.vue")
    // },
];

const router = new VueRouter({
    routes,
});

export default router;
