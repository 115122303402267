import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        CreateType: 0, //1->创建一级工程项目,2->创建二级工程
        ProjectListType: 1, //现在展示的数据是: 0->一级工程,1->二级工程,2->任务
        watchData: {},
        unit_id: -1,
        teamValue:false,
        device_id: -1,
        flyer_id: -1,
        task_id: "",
        page: 1,
        //通话接通属性
        isShowNewInvitationDialog: false,
        showVideo: false,
        isCall: true,
        darkTheme: true,
        // 文件上传
        uploadType: 5, // 文件类型:1模型,2正射,3快拼
        uploadResidue: 0, // 剩余存储空间
        airLineState: true, //航线选中状态
        panoramaState: true, //全景选中状态
        twoMarkState: true, //2D选中状态
        threeMarkState: true, //3D选中状态
        pitchMapCenter: [], //储存用户点击了指挥界面的图标点坐标
        mapZoom: null, //储存用户指挥界面的缩放级别
    },
    mutations: {
        // 保存分页器页数
        savePage(state, i) {
            state.page = i;
        },
        // 保存单位id
        saveUnit(state, i) {
            state.unit_id = i;
        },

        // 保存设备分类id
        saveDevice(state, i) {
            state.device_id = i;
        },
        // 保存飞手id
        saveFlyer(state, i) {
            state.flyer_id = i;
        },
        // 保存任务名id
        saveTask(state, i) {
            state.task_id = i;
        },
        // 修改主题色
        changeTheme(state, i) {
            state.darkTheme = i;
        },
        //

        changeFileType(state, i) {
            state.uploadType = i;
        },
        changeResidue(state, i) {
            state.uploadResidue = i;
        },

        SET_AirLineState(state, i) {
            state.airLineState = i;
        },
        SET_PanoramaState(state, i) {
            state.panoramaState = i;
        },
        SET_TwoMarkState(state, i) {
            state.twoMarkState = i;
        },
        SET_ThreeMarkState(state, i) {
            state.threeMarkState = i;
        },
        SET_PitchMapCenter(state, i) {
            state.pitchMapCenter = i
        },
        SET_MapZoom(state, i) {
            state.mapZoom = i
        },
        setTeamValue(state,i) {
            state.teamValue =i;
        },
    },
    actions: {},
    modules: {}
})
